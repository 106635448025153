import React, { useState, useMemo, useEffect } from "react"
import {
  Map,
  ZoomControl,
  Clusterer,
  FullscreenControl,
} from "react-yandex-maps"
import Pin from "../../assets/pin.svg"

const ProjectMap = ({ handleApiAvaliable, render }) => {
  const [zoom] = useState(11)
  const [template, setTemplate] = useState({ icon: null })
  const [center] = useState([58.015857, 56.241121])
  const mapState = useMemo(() => ({ center, zoom }), [center, zoom])
  const [isMounted, setMount] = useState()

  useEffect(() => {
    setMount(true) // note this flag denote mount status
    return () => {
      setMount(false)
    } // use effect cleanup to set flag false, if unmounted
  }, [])

  const setPin = ymaps => {
    const myIconLayout = ymaps.templateLayoutFactory.createClass(
      [
        '<svg class="icon-3e31be50" width="60" height="60" style="position: absolute; top: -60px; left: -20px;">',
        '<use xlink:href="#icon-3e31be50" xmlns:xlink="http://www.w3.org/1999/xlink"></use>',
        "</svg>",
      ].join("")
    )

    if (isMounted) setTemplate({ icon: myIconLayout })
  }

  return (
    <div
      style={{
        minHeight: "50vh",
        margin: "2rem 0",
        width: "100%",
        backgroundColor: "lightgrey",
      }}
    >
      <Pin />
      <Map
        modules={[
          "geolocation",
          "geocode",
          "templateLayoutFactory",
          "layout.ImageWithContent",
          "geoObject.addon.balloon",
          "geoObject.addon.hint",
        ]}
        onLoad={ymaps => {
          setPin(ymaps)
        }}
        state={mapState}
        style={{ height: "50vh", margin: "2rem 0", width: "100%" }}
        defaultState={{ center: [55.75, 37.57], zoom: 12 }}
      >
        <Clusterer
          options={{
            preset: "islands#nightClusterIcons",
            groupByCoordinates: false,
          }}
        >
          {render(template)}
        </Clusterer>
        <FullscreenControl />
        <ZoomControl />
      </Map>
    </div>
  )
}

export default ProjectMap
