import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Row from "../components/layout/row"
import Map from "../components/map/simpleMap"
import styled from "@emotion/styled"
import { Placemark } from "react-yandex-maps"
import useContacts from "../hooks/use-contacts"
import { getElementsByClassName } from '../helpers';
import { Helmet } from "react-helmet"

const ContactsWrapper = styled.div`
  display: flex;
  max-width: 50%;
  line-height: 1.5;
  h3 {
    font-size: 1rem;
  }
  ${props => props.theme.tablet} {
    max-width: 100%;
  }

  p {
    margin-bottom: 1rem;
  }
`
const Contacts = props => {
  const data = useContacts();
  const [address, setAdress] = useState("")
  const [contacts, setContacts] = useState("")

  useEffect(() => {
    setAdress(getElementsByClassName(data.content, "address"))
    setContacts(getElementsByClassName(data.content, "contacts"))
  }, [data.content])

  return (
    <Layout>
      <Helmet>
        <title>
          Контакты Фонда Защиты Прав Граждан участников строительства в ПК
        </title>
        <meta
          name="description"
          content="Контакты Фонд защиты прав граждан -  участников строительства ПК"
        />
      </Helmet>
      <Row margin="2rem auto">
        <div>
          <h1>Контакты Фонда</h1>
        </div>
        <ContactsWrapper>
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </ContactsWrapper>
        <Map
          render={template => (
            <Placemark
              geometry={[58.00541, 56.207605]}
              properties={{
                balloonContentHeader: `ППК Фонд`,
                balloonContentBody: `${contacts}`,
                balloonContentFooter: `${address}`,
                hintContent: `${address}`,
              }}
              options={{
                iconLayout: template.icon,
                iconShape: {
                  type: "Circle",
                  coordinates: [0, 0],
                  radius: 40,
                },
              }}
            />
          )}
        />
      </Row>
    </Layout>
  )
}

export default Contacts
